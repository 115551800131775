import subscriptions, {userSubscription} from './subscription';

const address = `
    firstName
    lastName
    address1
    address2
    city
    zipCode
    country
    countryCode
    locale
    state
`;
const getCart = `
    paymentTypes {
        method
        card {
            customerId
            brand
            expMonth
            expYear
            id
            last4
            name
            isDefault
            usedInSubscription
        }
        balance
    }
    country
    currency
    validation
    ttl
    basePrice
    priceWithoutReduction
    oldPrice
    appliedDiscountAmount
    price
    priceDetails {
        totalWithVat
        taxTotal
        total
    }
    subpriceDetails {
        totalWithVat
        taxTotal
        total
    }
    bundles {
        amount
        quantity
    }
    items {
        id
        name
        quantity
        reference
        price
        oldPrice
        priceExclTax
        currency
        thumbnailUrl
        type
        subtype
        deviceTarget
        metadata {
            amount
            sender {
                name
            }
            receiver {
                name
                email
            }
            sending_date
            message
            theme_reference
        }
    }
    discount {
        code
        mandatory
        amountLimit
        amount
        value
    }
    productsDiscount {
        name
        code
        mandatory
        amountLimit
        amount
        value
        reference
    }
    messages {
        code
        level
        message
        data
    }
    leftToPay {
        totalWithVat
    }
    accountBalance {
        balance
        amount
    }
    removedItems {
        code
        items {
            id
            name
            type
        }
    }
`;
const productItem = `
    id
    objectId
    reference
    catalog
    creationDate
    locale
    price
    type
    subtype
    version
    reduction
    oldPrice
    priceExclTax
    currency
    name
    title
    label
    buttonTarget
    image { url }
    target
    types
    stock
    deviceTarget
`;
const wishlistItem = `
    id
    objectId
    reference
    catalog
    creationDate
    locale
    price
    type
    reduction
    oldPrice
    priceExclTax
    currency
    name
    title
    label
    buttonTarget
    image { url }
    target
    stock
    types
    deviceTarget
`;
const codeAlbumValidation = `
    packs {
        title
        image {
            url
        }
        duration
        ageMin
        productId
        currency
        reference
        deviceTarget
    }
    type
    codeAlbumId
    countryCode
`;

const userAudiobooks = `
    items {
        id
        title
        image {
            url
        }
        label
        slug
        assets
        deviceTarget
    }
`;

export const MERGE_LEGACY_GIFT_CARD = (gql) => gql`
    mutation mergeLegacyGiftCard {
        mergeLegacyGiftCard {
            balance
        }
    }
`;
export const FIND_LEGACY_GIFT_CARD = (gql) => gql`
    query findLegacyGiftCard {
        findLegacyGiftCard {
            legacy
            amount
            numberOfLegacyGiftCard
        }
    }
`;
export const ADD_GIFT_CARD_TO_BALANCE = (gql) => gql`
    mutation addGiftCardToBalance($data: AddGiftCardToBalanceInput!) {
        addGiftCardToBalance(data: $data) {
            balance
            expirationDate
            valid
        }
    }
`;
export const FIND_GIFT_CARD_BALANCE = (gql) => gql`
    query findGiftCardBalance {
        findGiftCardBalance {
            balance
            expirationDate
            valid
        }
    }
`;
export const SUBSCRIBE_NEWSLETTER = (gql) => gql`
    mutation subscribeNewsletter($data: SubscribeNewsletterInput!) {
        subscribeNewsletter(data: $data) {
            success
        }
    }
`;
export const UNSUBSCRIBE_NEWSLETTER = (gql) => gql`
    mutation unsubscribeNewsletter($data: UnsubscribeNewsletterInput!) {
        unsubscribeNewsletter(data: $data) {
            email
        }
    }
`;
export const GET_PURCHASE = (gql) => gql`
    query getPurchase($id: ID!) {
        getPurchase(id: $id) {
            id
            status
            modificationDate
            currency
            priceDetails {
                total
                totalWithVat
                taxTotal
            }
            products {
                productId
                name
                quantity
                price
                priceExclTax
                reference
            }
            shipping {
                reference
                price
            }
            paymentMethod
            purchaseNumber
            discount {
                codeId
                offerId
                code
                price {
                    totalWithVat
                    taxTotal
                    total
                }
            }
            productsDiscounts {
                codeId
                offerId
                products
                code
            }
            giftCard {
                giftCardId
                creationDate
                expirationDate
                reference
                balance
                status
                currency
                amount
            }
            accountBalance {
                balance
                amount
            }
        }
    }
`;
export const FIND_PURCHASES = (gql) => gql`
    query findPurchases($withInvoices: Boolean) {
        findPurchases(withInvoices: $withInvoices) {
            items {
                id
                status
                modificationDate
                currency
                price
                paymentMethod
                products {
                    productId
                    name
                    quantity
                    price
                    reference
                    tableName
                }
                invoice {
                    id
                    invoiceId
                    url
                    status
                    creationDate
                    modificationDate
                    purchaseNumber
                }
            }
        }
    }
`;
export const ADD_CREDITCARD = (gql) => gql`
    mutation addCreditCard($data: AddCreditCardInput!) {
        addCreditCard(data: $data) {
            creditCardId
        }
    }
`;
export const FIND_CREDITCARDS = (gql) => gql`
    query findCreditCards($filterExpired: Boolean) {
        findCreditCards(filterExpired: $filterExpired) {
            items {
                method
                card {
                    customerId
                    brand
                    expMonth
                    expYear
                    id
                    last4
                    name
                    isDefault
                    usedInSubscription
                }
            }
        }
    }
`;
export const GET_LIBRARY = (gql) => gql`
    query getLibrary {
        getLibrary {
            packs {
                items {
                    createdAt
                    packId
                    reference
                }
            }
        }
    }
`;

// userAudiobooks
export const FIND_LIBRARY_STUDIO = (gql) => gql`
    query findLibraryStudio {
        findLibraryStudio {
            items {
                title
                image {
                    url
                }
                label
            }
        }
    }
`;
export const CREATE_PURCHASE_PAYMENT = (gql) => gql`
    mutation createPurchasePayment($data: CreatePurchasePaymentInput!) {
        createPurchasePayment(data: $data) {
            stripe {
                id
                status
                nextAction
                paymentMethod
                clientSecret
            }
            paypal {
                redirectToUrl
                method
            }
        }
    }
`;
export const CREATE_PURCHASE_PAYMENT_FREE = (gql) => gql`
    mutation createPurchasePayment($data: CreatePurchasePaymentFreeInput!) {
        createPurchasePaymentFree(data: $data) {
            success
        }
    }
`;
export const COMPUTE_ANONYMOUS_SHOPPING_CART = (gql) => gql`
    mutation computeAnonymousCart($data: ComputeAnonymousCartInput!) {
        computeAnonymousCart(data: $data) {
            ${getCart}
        }
    }
`;
export const FIND_SHOPPING_CART_AVAILABLE_SHIPPING_METHODS = (gql) => gql`
    query findCartAvailableShippingMethods($locale: String!) {
        findCartAvailableShippingMethods(locale: $locale) {
            items {
                id
                title
                description
                selectedByDefault
                reference
                price
            }
        }
    }
`;
export const ACTIVATE_SHOPPING_CART_DISCOUNT_CODE = (gql) => gql`
    mutation activateCartDiscountCode($data: ActivateCartDiscountCodeInput!) {
        activateCartDiscountCode(data: $data) {
            discount
            productsDiscount
        }
    }
`;
export const DELETE_SHOPPING_CART_DISCOUNT_CODE = (gql) => gql`
    mutation deleteCartDiscountCode($data: DeleteCartDiscountCodeInput!) {
        deleteCartDiscountCode(data: $data) {
            success
        }
    }
`;
export const RETRIEVE_ADDRESSES = (gql) => gql`
    mutation retrieveAddresses($data: RetrieveAddressesInput!) {
        retrieveAddresses(data: $data) {
            addresses {
                address1
                zipCode
                city
                country
                state
            }
        }
    }
`;
export const UPDATE_ADDRESS = (gql) => gql`
    mutation updateAddress($data: UpdateAddressInput!) {
        updateAddress(data: $data) {
            success
        }
    }
`;
export const GET_SHOPPING_CART = (gql) => gql`
    query getCart {
        getCart {
            ${getCart}
        }
    }
`;
export const VALIDATE_SHOPPING_CART = (gql) => gql`
    mutation validateCart($data: ValidateCartInput!) {
        validateCart(data: $data) {
            id
        }
    }
`;
export const INVALIDATE_SHOPPING_CART = (gql) => gql`
    mutation invalidateCart {
        invalidateCart {
            success
        }
    }
`;
export const SAVE_SHOPPING_CART = (gql) => gql`
    mutation saveCart($data: SaveCartInput!) {
        saveCart(data: $data) {
            ${getCart}
        }
    }
`;
export const RESET_SHOPPING_CART = (gql) => gql`
    mutation resetCart {
        resetCart {
            id
        }
    }
`;
export const CONTACT = (gql) => gql`
    mutation contact($data: ContactInput!) {
        contact(data: $data) {
            email
        }
    }
`;
export const RETRIEVE_USER = (gql) => gql`
    query retrieveUser {
        retrieveUser {
            id
            email
            firstName
            lastName
            billingInfos {
                ${address}
            }
            deliveryInfos {
                ${address}
                phone
            }
            locale
            newsletter {
                active
                asked
                modificationDate
            }
        }
    }
`;
export const GET_CURRENT_USER = (gql) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            firstName
            lastName
            billingInfos {
                ${address}
            }
            deliveryInfos {
                ${address}
                phone
            }
            locale
            ownedPacksReferences
            hasFahs
            hasFlam
            purchaseCount
            newsletter {
                active
                asked
                modificationDate
            }
            subscription {
                ${userSubscription}
            }
            hadMonthlySubscriptionFreeMonth
        }
    }
`;
export const UPDATE_USER_LOCALE = (gql) => gql`
    mutation updateUserLocale($data: UpdateUserLocaleInput!) {
        updateUserLocale(data: $data) {
            success
        }
    }
`;
export const LOGIN = (gql) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const FORGOT_PASSWORD = (gql) => gql`
    mutation forgotPassword($data: ForgotPasswordInput!) {
        forgotPassword(data: $data) {
            success
        }
    }
`;
export const RESEND_VALIDATION_EMAIL = (gql) => gql`
    mutation resendValidationEmail($data: ResendValidationEmailInput!) {
        resendValidationEmail(data: $data) {
            success
        }
    }
`;
export const UPDATE_PASSWORD = (gql) => gql`
    mutation updatePassword($data: UpdatePasswordInput!) {
        updatePassword(data: $data) {
            token
            refreshToken
        }
    }
`;
export const REGISTER = (gql) => gql`
    mutation register($data: RegisterInput!) {
        register(data: $data) {
            success
        }
    }
`;
export const REFRESH_LOGIN = (gql) => gql`
    mutation refreshLogin($data: RefreshAuthTokenInput!) {
        refreshAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const USER_UPDATE = (gql) => gql`
    mutation userUpdate($data: UpdateUserInput!) {
        updateUserInfos(data: $data) {
            success
        }
    }
`;

export const SUBSCRIBE_NEWSLETTER_AUTHENTIFIED = (gql) => gql`
    mutation subscribeNewsletterAuthentified($data: SubscribeNewsletterAuthentifiedInput!) {
        subscribeNewsletterAuthentified(data: $data) {
            success
        }
    }
`;

export const FIND_PRODUCT_RECOMMENDATIONS = (gql) => gql`
    query findProductRecommendations($data: FindProductRecommendationsInput!) {
        findProductRecommendations(data: $data) {
            items {
                ${productItem}
            }
        }
    }
`;

export const FIND_USER_RECOMMENDATIONS = (gql) => gql`
    query getUserRecommendations {
        findUserRecommendations {
            items {
                ${productItem}
            }
        }
    }
`;

/**
 * https://server-backend-dev.lunii.com/api-docs/#/User/getUserDevices
 */
export const GET_USER_DEVICES = (gql: any) => gql`
    query getUserDevices {
        getUserDevices {
            items {
                id
                name
                reference
                version
                packId
                serialNumber
                serialNumberTypedByUser
                illustrationUrl
                avatarId
                lastSyncedAt
                avatar {
                    id
                    imageUrl
                    bitmapUrl
                }
                metadata {
                    firmwareVersion
                    deviceId
                    productId
                    sdCardSize
                    vendorId
                }
                associationStatus
                createdAt
            }
        }
    }
`;

export const GET_USER_AUDIOBOOKS = (gql) => gql`
    query getUserAudiobooks {
        getUserAudiobooks {
            ${userAudiobooks}
        }
    }
`;

export const GET_USER_FAH_AUDIOBOOKS = (gql) => gql`
    query getUserFahAudiobooks {
        getUserFahAudiobooks {
            ${userAudiobooks}
        }
    }
`;

export const GET_USER_FLAM_AUDIOBOOKS = (gql) => gql`
    query getUserFlamAudiobooks {
        getUserFlamAudiobooks {
            ${userAudiobooks}
        }
    }
`;



export const RETRIEVE_FAH = (gql) => gql`
    query retrieveFahs {
        retrieveFahs {
            items {
                fahId
                name
                creationDate
                name
                packId
                serialNumber
                serialNumberTypedByUser
                metadata {
                    firmwareVersion
                    productId
                    sdCardSize
                    vendorId
                }
                reference
                illustration {
                    url
                }
            }
        }
    }
`;
export const UPDATE_FAH_NAME = (gql) => gql`
    mutation updateFahName($data: UpdateFahNameInput!) {
        updateFahName(data: $data) {
            success
        }
    }
`;
export const UPDATE_FAH_SERIAL = (gql) => gql`
    mutation updateFahSerial($data: UpdateFahSerialInput!) {
        updateFahSerial(data: $data) {
            success
        }
    }
`;

// query validate codeAlbum
export const RETRIEVE_CODE_ALBUMS_INFOS = (gql) => gql`
    query retrieveCodeAlbumInfos ($data: retrieveCodeAlbumInfosInput!){
        retrieveCodeAlbumInfos (data: $data){
            ${codeAlbumValidation}
        }
    }
`;

// query activate codeAlbum
export const ACTIVATE_CODE_ALBUM = (gql) => gql`
    mutation activateCodeAlbum($data: CodeAlbumActivationInput!) {
        activateCodeAlbum(data: $data) {
            success
        }
    }
`;

/**
 * WISHLIST START // DEPRECATED
 */
export const RETRIEVE_WISHLIST_ITEM_FRAGMENT = (gql) => gql`
    fragment WishlistItemFragment on WishlistItem {
        ${wishlistItem}
        _removed @client
    }
`;
export const RETRIEVE_WISHLIST = (gql) => gql`
    ${RETRIEVE_WISHLIST_ITEM_FRAGMENT(gql)}
    query retrieveWishlist($data: RetrieveWishlistInput) {
        retrieveWishlist(data: $data) {
            items {
                ...WishlistItemFragment
            }
        }
    }
`;
export const ADD_TO_WISHLIST = (gql) => gql`
    ${RETRIEVE_WISHLIST_ITEM_FRAGMENT(gql)}
    mutation addToWishlist($data: UpdateWishlistInput!) {
        addToWishlist(data: $data) {
            ...WishlistItemFragment
        }
    }
`;
export const REMOVE_FROM_WISHLIST = (gql) => gql`
    ${RETRIEVE_WISHLIST_ITEM_FRAGMENT(gql)}
    mutation removeFromWishlist($data: UpdateWishlistInput!) {
        removeFromWishlist(data: $data) {
            ...WishlistItemFragment
        }
    }
`;
/**
 * WISHLIST END
 */


// WISHLIST BACKEND


export const GET_USER_WISHLIST = (gql) => gql`
    query getUserWishlist($data: GetUserWishlistInput!) {
        getUserWishlist(data: $data) {
            items {
                ${wishlistItem}
            }
        }
    }
`;

export const ADD_TO_USER_WISHLIST = (gql) => gql`
    mutation addToUserWishlist($data: AddToUserWishlistInput!) {
        addToUserWishlist(data: $data) {
            ${wishlistItem}
        }
    }
`;

export const REMOVE_FROM_USER_WISHLIST = (gql) => gql`
    mutation removeFromUserWishlist($data: RemoveFromUserWishlistInput!) {
        removeFromUserWishlist(data: $data) {
            ${wishlistItem}
        }
    }
`;

export * from './subscription';

export const queries = {
    MERGE_LEGACY_GIFT_CARD,
    FIND_LEGACY_GIFT_CARD,
    ADD_GIFT_CARD_TO_BALANCE,
    FIND_GIFT_CARD_BALANCE,
    SUBSCRIBE_NEWSLETTER,
    UNSUBSCRIBE_NEWSLETTER,
    GET_PURCHASE,
    FIND_PURCHASES,
    ADD_CREDITCARD,
    FIND_CREDITCARDS,
    GET_LIBRARY,
    FIND_LIBRARY_STUDIO,
    CREATE_PURCHASE_PAYMENT,
    CREATE_PURCHASE_PAYMENT_FREE,
    COMPUTE_ANONYMOUS_SHOPPING_CART,
    FIND_SHOPPING_CART_AVAILABLE_SHIPPING_METHODS,
    ACTIVATE_SHOPPING_CART_DISCOUNT_CODE,
    DELETE_SHOPPING_CART_DISCOUNT_CODE,
    RETRIEVE_ADDRESSES,
    UPDATE_ADDRESS,
    GET_SHOPPING_CART,
    VALIDATE_SHOPPING_CART,
    INVALIDATE_SHOPPING_CART,
    SAVE_SHOPPING_CART,
    RESET_SHOPPING_CART,
    CONTACT,
    RETRIEVE_USER,
    GET_CURRENT_USER,
    UPDATE_USER_LOCALE,
    LOGIN,
    FORGOT_PASSWORD,
    RESEND_VALIDATION_EMAIL,
    UPDATE_PASSWORD,
    REGISTER,
    REFRESH_LOGIN,
    USER_UPDATE,
    SUBSCRIBE_NEWSLETTER_AUTHENTIFIED,
    FIND_PRODUCT_RECOMMENDATIONS,
    FIND_USER_RECOMMENDATIONS,
    RETRIEVE_FAH,
    GET_USER_DEVICES,
    UPDATE_FAH_NAME,
    UPDATE_FAH_SERIAL,
    RETRIEVE_CODE_ALBUMS_INFOS,
    ACTIVATE_CODE_ALBUM,
    RETRIEVE_WISHLIST_ITEM_FRAGMENT,
    RETRIEVE_WISHLIST,
    ADD_TO_WISHLIST,
    REMOVE_FROM_WISHLIST,
    GET_USER_AUDIOBOOKS,
    GET_USER_FAH_AUDIOBOOKS,
    GET_USER_FLAM_AUDIOBOOKS,
    GET_USER_WISHLIST,
    ADD_TO_USER_WISHLIST,
    REMOVE_FROM_USER_WISHLIST,
    ...subscriptions,
};
