import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Trans} from 'react-i18next';
import {cssFont, cssText} from '@ohoareau/css-utils';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {HistoryLocation, useLocation} from '@reach/router';
import {MosaicCardItem, NavBarRootModeEnum, Row, Spinner, useLibraryStudio, useLuniiNavigation, useLuniiPage,
    useLuniiTranslation, useLuniiUser, user_audiobook} from '../../../ui';
import {
    LibraryEmpty, LiveElement, usePageTracking, Illustration, useUserAudiobooks, catalogToLocale,
    PageAppBarModule, Link, ProductUnavailableModal, getTargetDevice} from '../../..';
import * as propertyMappers from '../../../services/property-mappers';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(5),
        },
    },
    header: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5),
        },
    },
    title: {
        ...cssText(theme, 'standard', 'large_title', undefined, undefined, '#2C3637'),
    },
    description: {
        ...cssText(theme, 'standard', 'body', undefined, undefined, '#5F6769'),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'secondary_body'),
        },
    },
    link: {
        ...cssFont(theme, 'standard', 'button_1'),
        [theme.breakpoints.down('sm')]: {
            ...cssFont(theme, 'standard', 'button_2'),
        },
    },
    images: {
      marginTop: theme.spacing(3),
    },
    image: {
        cursor: 'pointer',
        borderRadius: 8,
        opacity: .4,
        border: '1px solid transparent',
        transition: 'opacity .2s, border-color .2s',
    },
    fahImage: {
        opacity: 1,
        borderColor: '#47C7F5',
        cursor: 'default',
    },
    flamImage: {
        opacity: 1,
        borderColor: '#FBBD8E',
        cursor: 'default',
    },
    subMenuContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: theme.spacing(5, 0, 4, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(5, 0, 3, 0),
        }
    },
    subMenu: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        ...cssText(theme, 'standard', 'body_thick', undefined, undefined, '#7C878B'),
        transition: 'color .2s',
        marginRight: theme.spacing(2),
        '&:last-child': {
            marginRight: 0,
        },
    },
    activeSubMenu: {
        color: '#2C3637',
        cursor: 'default',
    },
    underline: {
        marginTop: 2,
        height: 4,
        borderRadius: 2,
        width: '100%',
        backgroundColor: 'transparent',
        transition: 'background-color .2s',
    },
    activeUnderline: {
        backgroundColor:'#FBBD2F',
    },
    emptyLibrary: {
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(5),
        },
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        gap: 24,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gap: 16,
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr',
            gap: 8,
        },
        '& > *': {
            width: '100%',
            minWidth: '100%',
            maxWidth: '100%',
        }
    },
}));

export function UserAudiobooksScreen() {
    usePageTracking(true, 'bibliotheque');
    const {user} = useLuniiUser();
    const location: HistoryLocation = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const urlTarget = urlParams.get('target');
    window?.history?.replaceState(null, '', window?.location?.pathname);
    const {audiobooks, loading, refetch} = useUserAudiobooks();
    // todo warning should use new studio audiobooks backend route
    const [retrieveUserStudioAudiobooks, {loading: studioLoading, data: studioAudiobooks}] = useLibraryStudio();
    const [open, setOpen] = useState<boolean>(false);
    const formattedAudiobooks = audiobooks.map((audiobook: user_audiobook) => ({
        ...audiobook,
        ...(!audiobook.target && {target: setOpen})
    }))
    const {...p} = useLuniiPage();
    propertyMappers.menu((p as any).menu[p?.locale || ''], p, 'menu');
    const menuImage = ((p.menuImage as any) || {})[p?.locale || ''];
    const classes = useStyles();
    const {t} = useLuniiTranslation();
    const {goSupport} = useLuniiNavigation();
    const menu = [
        {
            id: 'fah',
            image: 'multi_product_fah_picture',
            alt: t('catalog_fah'),
            items: [
                {
                    id: 'audiobooks',
                    label: t('library_menu_luniistore'), // old key but same translation
                    live_element: 'my_audiobooks_fah_audiobooks_empty',
                },
                {
                    id: 'studio',
                    label: t('library_menu_studio'),
                    live_element: 'my_audiobooks_fah_studio_empty',
                },
            ],
        },
        {
            id: 'flam',
            alt: t('catalog_flam'),
            image: 'multi_product_flam_picture',
            items: [
                {
                    id: 'audiobooks',
                    label: t('library_menu_luniistore'), // old key but same translation
                    live_element: 'my_audiobooks_flam_audiobooks_empty',

                },
                {
                    id: 'studio',
                    label: t('library_menu_studio'),
                }
            ],
        },
    ];
    const [activeMenu, setActiveMenu] = useState<string[]>([(menu.find(m => m.id === urlTarget) || menu[0]).id, menu[0].items[0].id])
    const filteredAudiobooks = (activeMenu[1] === 'audiobooks' && formattedAudiobooks // audiobooks
        || (activeMenu[1] === 'studio' && activeMenu[0] !== 'flam') && studioAudiobooks // studio audiobooks only if fah is selected
        || [])
        .filter((audiobook: user_audiobook) => {
            const deviceTarget = getTargetDevice(audiobook.deviceTarget, false);
            return deviceTarget === activeMenu[0];
        });
    useEffect(() => {
        refetch();
        retrieveUserStudioAudiobooks();
    }, [refetch, retrieveUserStudioAudiobooks, activeMenu])
    return (
        <div className={classes.root}>
            <PageAppBarModule
                menu={p?.menu || []}
                menuImage={menuImage}
                rootMode={NavBarRootModeEnum.Library}
            />
            {/* Header */}
            <Row className={classes.header} padding='none' justify='flex-start'>
                <Grid lg={6} md={7} xs={12}>
                    <div className={classes.title}>{t('library_title')}</div>
                    <div className={classes.description}>
                        <Trans
                            i18nKey='my_library_subtitle'
                            components={{
                                component: <Link className={classes.link} text={t('my_device_fah')} url={(e: any) => goSupport && goSupport(e, 'articles/10765266418973')}/>,
                                component2: <Link className={classes.link} text={t('my_device_flam')} url={(e: any) => goSupport && goSupport(e, 'articles/13127726827037')}/>
                            }}/>
                    </div>
                </Grid>
            </Row>
            {/* Images menu */}
            <Row padding='none' justify='flex-start' className={classes.images}>
                <Grid lg={12} md={12} xs={12} className={classes.grid}>
                    {menu.map(({id, image, alt}) => (
                        <Illustration
                            className={clsx(classes.image, id === activeMenu[0] && (classes as any)[`${id}Image`])}
                            alt={alt}
                            onClick={() => id !== activeMenu[0] && setActiveMenu((prevState) => [id, prevState[1]])}
                            name={image}/>
                    ))}
                </Grid>
                <Grid lg={12} md={12} xs={12} className={classes.subMenuContainer}>
                    {menu.find(({id}) => id === activeMenu[0])?.items.map(({id, label}) => (
                        <div
                            className={clsx(classes.subMenu, id === activeMenu[1] && classes.activeSubMenu)}
                            onClick={() => id !== activeMenu[1] && setActiveMenu((prevState) => [prevState[0], id])}>
                            {label}
                            <div className={clsx(classes.underline, id === activeMenu[1] && classes.activeUnderline)}/>
                        </div>
                    ))}
                </Grid>
            </Row>
            {/* Empty library */}
            {!loading && !studioLoading && !filteredAudiobooks?.length && (
                <>
                    <Row padding='none' margins='none'>
                        <Grid lg={4} md={6} xs={12}>
                            <LibraryEmpty className={classes.emptyLibrary} mode={activeMenu.join('_') as any}/>
                        </Grid>
                    </Row>
                    <Row padding='none' justify='flex-start'>
                        <Grid lg={12} md={12} xs={12}>
                            <LiveElement
                                uid={`my_audiobooks_${activeMenu.join('_')}_empty`}
                                locale={catalogToLocale(user?.locale) || 'fr-fr'}
                                contentPadding='none'
                                imagePosition='right'
                            />
                        </Grid>
                    </Row>
                </>
            ) || false}
            {/* Library */}
            {!loading && !studioLoading && filteredAudiobooks?.length && (
                <Row padding='none' justify='flex-start'>
                    <Grid lg={12} md={12} xs={12} className={classes.grid}>
                        {filteredAudiobooks.map((audiobook: user_audiobook, index: number) => {
                            const item = {
                                ...audiobook,
                                ...(audiobook.assets && {
                                    buttonTarget: audiobook.assets,
                                    buttonLabel: t('pack_assets_label'),
                                    buttonType: 'primary,startIcon=file',
                                }),
                            };
                            return (
                                <MosaicCardItem
                                    key={Math.random()}
                                    {...item}
                                    imageLoading={index > 15 ? 'lazy' : 'eager'}
                                />
                            );
                        })}
                    </Grid>
                </Row>
            ) || false}
            {(loading || studioLoading) && <Spinner/> || false /* loader */}
            <ProductUnavailableModal open={open} onCloseCallback={() => setOpen(false)}/> {/* unavailable product modal */}
        </div>
    );
}

export default UserAudiobooksScreen;
